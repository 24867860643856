import React from "react";
import "./FlipCard.css";

const FlipCard = ({ employer, employerImg, jobTitle, date, descs }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front p-4">
          <img
            src={employerImg.img}
            alt={employer}
            className="w-16 h-16 mx-auto mb-4 rounded-full"
          />
          <h1 className="text-center">{employer}</h1>
          <h3 className="text-center">{jobTitle}</h3>
          <p className="text-center ">{date}</p>
        </div>
        <div className="flip-card-back p-4 bg-gray-100 overflow-y-auto">
          <ul className="list-disc list-inside text-left">
            {descs.map((desc, index) => (
              <li key={index}>{desc}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
