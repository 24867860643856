import { React, useState, useEffect } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Grid,
  useStyleConfig,
  Text,
  useColorModeValue,
  useColorMode,
  Skeleton,
} from "@chakra-ui/react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Navbar from "../../components/Navbar/Navbar";
import routes from "../../routes";
import TechStackCard from "../../components/techStackCard/techStackCard";
import AboutMeInfo from "../../components/aboutMeInfo/aboutMeInfo";
import typescript from "../../assets/icons/typescript.svg";
import node from "../../assets/icons/nodejs.svg";
import mongo from "../../assets/icons/mongodb.svg";
import js from "../../assets/icons/javascript.svg";
import react from "../../assets/icons/react.svg";
import laravel from "../../assets/icons/laravel-2.svg";
import mysql from "../../assets/icons/mysql-3.svg";
import postgresql from "../../assets/icons/postgresql.svg";
import php from "../../assets/icons/php.svg";
import git from "../../assets/icons/git.svg";
import express from "../../assets/icons/express.svg";
import myphoto from "../../assets/aboutMePhoto.jpg";

import { expData } from "./expData";
import FlipCard from "../../components/FlipCard/FlipCard";
export default function About() {
  const [loadedProfileimg, setloadedProfileimg] = useState(false);
  const [loadedgithubimg, setloadedgithubimg] = useState(false);
  const styles = useStyleConfig("Card");
  const { colorMode } = useColorMode();
  const textColorPrimary = useColorModeValue("lightblue.100", "lightpeach.100");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "40px", md: "80px", xl: "80px" }}>
        <Flex direction="row" justifyContent="center" alignItems="center">
          {/* <TypeAnimation
            sequence={["ABOUT ME", 500, "ABOUT", 500, "ABOUT ME", 500]}
            style={{ fontSize: "3em" }}
            cursor={false}
          /> */}
          <Grid
            templateColumns={{
              base: "1fr",
              lg: "1.34fr 1.62fr",
            }}
            templateRows={{
              lg: "1fr",
            }}
            gap={{ base: "20px", xl: "20px" }}
          >
            <Flex
              position="relative"
              // w={{ base: "100%", sm: "50%", md: "40%" }}
              // h={{ base: "100%", sm: "80%", md: "70%" }}
              justifyContent="center"
              justifyItems="center"
              alignItems="center"
              w={{ base: "100%", sm: "80%", md: "70%" }}
              h={{ base: "100%", sm: "80%", md: "70%" }}
              p="20px"
              borderRadius="20px"
              overflow="hidden"
            >
              <Box
                __css={styles}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bg={textColorPrimary}
                transform="rotate(4deg)"
                // zIndex="-1"
              />
              <Flex height="100%" justifyContent="center" alignItems="center">
                <Skeleton
                  height="100%"
                  isLoaded={loadedProfileimg}
                  color="white"
                  fadeDuration={1}
                >
                  <LazyLoadImage
                    src={myphoto}
                    width="auto"
                    height="100%"
                    alt="Image Alt"
                    effect="blur"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                    onLoad={() => setloadedProfileimg(true)}
                  />
                </Skeleton>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box __css={styles} border="none" bg="transparent">
                <Text
                  color={textColorPrimary}
                  fontSize="2xl"
                  mb="40px"
                  textAlign="center"
                >
                  {"<GENERAL INFORMATION/>"}
                </Text>
                <SimpleGrid columns="2" gap="20px">
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Name"
                    value="Maulana Akbar Sabilillah"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Github"
                    value="github.com/moechacino"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Institution"
                    value="Politeknik Elektronika Negeri Surabaya"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="E-Mail"
                    value="lanaksa28@gmail.com"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Degree"
                    value="Undergraduate Bachelor of Applied Science in Internet Engineering Technology"
                  />
                  <AboutMeInfo
                    boxShadow={cardShadow}
                    title="Languages"
                    value="Bahasa Indonesia, English"
                  />
                </SimpleGrid>
              </Box>
            </Flex>
          </Grid>
        </Flex>

        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<MY SKILLSET/>"}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ base: 2, md: 2, lg: 3, xl: 6, "2xl": 6 }}
          gap="20px"
          mb="20px"
          align={{ base: "center", xl: "center" }}
          justify={{ base: "center", xl: "center" }}
        >
          <TechStackCard imagepath={node} />
          <TechStackCard imagepath={express} background="white" />
          <TechStackCard imagepath={react} />
          <TechStackCard imagepath={laravel} />
          <TechStackCard imagepath={typescript} />
          <TechStackCard imagepath={js} />
          <TechStackCard imagepath="https://www.php.net/images/logos/new-php-logo.svg" />
          <TechStackCard imagepath={mysql} />
          <TechStackCard imagepath={postgresql} />
          <TechStackCard imagepath={mongo} />
          <TechStackCard imagepath={git} />
        </SimpleGrid>

        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<WORK EXPERIENCE/>"}
          </Text>
        </Flex>
        <Flex justifyContent="center">
          <div className="flex justify-center items-center h-screen bg-gray-100">
            {expData.map((exp) => (
              <FlipCard
                key={exp.id}
                employer={exp.employer}
                employerImg={exp.employerImg}
                jobTitle={exp.jobTitle}
                date={exp.date}
                descs={exp.descs}
              />
            ))}
          </div>
        </Flex>
        {/* <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Text color={textColorPrimary} fontSize="2em" textAlign="center">
            {"<ACHIEVEMENT/>"}
          </Text>
        </Flex> */}
      </Box>
    </Box>
  );
}
