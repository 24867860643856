import ads from "../../assets/experiences/ads-biru.png";
export const expData = [
  {
    id: 1,
    employer: "ADS Digital Partner",
    employerImg: {
      img: ads,
      bkgColor: "#474044",
    },
    jobTitle: "Backend Engineer Intern",
    date: "02/2024 - 06/2024",
    descs: [
      "Design the backend architecture",
      "Design the database",
      "Develop API services for data communication between server and client",
      "Implement the functional business processes of the application from the server side ",
      "Perform unit testing, integration testing, and performance testing",
      "Fix bugs and solve technical problems on the backend side of the application",
      "Implement a security system on the backend side",
      "Create API documentation and system documentation",
    ],
  },
];
