import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdBarChart, MdPersonPin, MdCode, MdContacts } from "react-icons/md";

import About from "./views/about/about";
import Resume from "./views/resume/resume";
import Projects from "./views/projects/projects";
import Contact from "./views/contact/contact";

const routes = [
  {
    name: "About Me",
    path: "/about",
    navbarDisplayName: "<About Me/>",
    icon: <Icon as={MdPersonPin} width="30px" height="30px" color="inherit" />,
    component: About,
  },
  {
    name: "Projects / Portfolios",
    path: "/projects",
    navbarDisplayName: "<Projects/>",
    icon: <Icon as={MdCode} width="30px" height="30px" color="inherit" />,
    component: Projects,
  },
  {
    name: "Resume",
    path: "/resume",
    navbarDisplayName: "<Resume/>",
    icon: <Icon as={MdBarChart} width="30px" height="30px" color="inherit" />,
    component: Resume,
  },
  {
    name: "Contact Me",
    path: "/contact",
    navbarDisplayName: "<Contact/>",
    icon: <Icon as={MdContacts} width="30px" height="30px" color="inherit" />,
    component: Contact,
  },
];

export default routes;
