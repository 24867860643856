import { React, useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import routes from "../../routes";
import Navbar from "../../components/Navbar/Navbar";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import suaradesamu from "../../assets/projects/suaradesamu.jpg";
import kerjapraktikAPI from "../../assets/projects/kerjapraktik-api.jpeg";
import kidcarehub from "../../assets/projects/kidcarehub.jpg";
import labolatoriumInventory from "../../assets/projects/labolatorium-inventory.jpg";
import accountSaver from "../../assets/projects/account-saver-dashboard.jpg";
import spk_saw from "../../assets/projects/spk_saw.jpg";
import sewa_gedung from "../../assets/projects/sewa_gedung.jpg";
export default function Projects() {
  const textColorPrimary = useColorModeValue(
    "secondaryGray.900",
    "lightpeach.100"
  );
  const textColor = useColorModeValue("navy.700", "lightpeach.100");

  const [filter, setFilter] = useState("All");

  const projectData = [
    {
      projectImage: accountSaver,
      projectName: "Account Saver Lite",
      aboutProject:
        "BUT TRY IT! Just for fun project, created because i have many accounts and sometimes i forgot it. This Website use AES-256-CBC for encrypting saved password account. So the developers can not see it in database, but you and GOD",
      techStack: "Laravel | InertiaJS | ReactJS | MySQL",
      projectLink: "https://github.com/moechacino/Account-Management",
      projectLiveLink: "https://account-saver.moechacino.my.id/",
    },
    {
      projectImage: suaradesamu,
      projectName: "Suaradesamu - API",
      aboutProject:
        "Mobile application that use blockchain technology for head village election",
      techStack:
        "NodeJs | Fastify | Flask | PyTorch | MySQL | Solidity | Ethereum | Web3.js | Truffle Ganache",
      projectLink: "https://github.com/moechacino/suaradesamu-api",
    },
    {
      projectImage: kerjapraktikAPI,
      projectName: "Kerja Praktik & MBKM Management - API",
      aboutProject:
        "A web platform that provides facilities for students and lecturers to report activities during Practical Work and MBKM.",
      techStack: "NodeJs | ExpressJs | MongoDB | S3",
      projectLink: "https://github.com/moechacino/kerjapraktikmbkm-api",
    },
    {
      projectImage: kidcarehub,
      projectName: "KidcareHub - API",
      aboutProject:
        "Mobile Application for Information, Health Services, and Monitoring for Toddlers.",
      techStack: "NodeJs | ExpressJs | SocketIO | MySQL",
      projectLink: "https://github.com/moechacino/kidcarehub",
    },
    {
      projectImage: sewa_gedung,
      projectName: "Dashboard Manajemen Sewa Gedung",
      aboutProject: "This is Web Dashboard for managing building rental",
      techStack: "Laravel | Blade | MySQL",
      projectLiveLink: "https://sewa-gedung.moechacino.my.id/",
    },
    {
      projectImage: labolatoriumInventory,
      projectName: "Cloud Based Labolatorium Inventory",
      aboutProject:
        "Inventory management system for a laboratory. Server use RESTful API for client-server communication and use MQTT Protocols to connect with the IoT devices integrated into the lab to detect items",
      techStack: "Laravel | REST API | MYSQL | MQTT",
      projectLink:
        "https://github.com/moechacino/cloudBased_LabolatoriumInventory",
    },
    {
      projectImage: spk_saw,
      projectName: "Dashboard Sistem Pengambil Keputusan Metode SAW",
      aboutProject:
        "This Dashboard is a tool designed to simplify the criteria-based decision making process. Using the SAW method, this system allows users to assess and compare various alternatives by considering several predetermined criteria.",
      techStack: "Laravel | Blade | MySQL",
      projectLiveLink: "https://spk-saw.moechacino.my.id/",
    },
  ];

  const filteredProjects = projectData.filter((project) => {
    if (filter === "All") return true;
    return project.techStack.includes(filter);
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return routes[i].navbarDisplayName;
      }
    }
  };

  return (
    <Box>
      <Navbar displayText={getActiveRoute(routes)} />
      <Box pt={{ base: "20px", md: "40px", xl: "40px" }}>
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb="40px"
        >
          <Button
            onClick={() => setFilter("All")}
            colorScheme={filter === "All" ? "teal" : "gray"}
            color={filter === "All" ? "black" : textColor}
            mr="10px"
          >
            All
          </Button>
          <Button
            onClick={() => setFilter("Laravel")}
            colorScheme={filter === "Laravel" ? "teal" : "gray"}
            color={filter === "Laravel" ? "black" : textColor}
            mr="10px"
          >
            Laravel
          </Button>
          <Button
            onClick={() => setFilter("NodeJs")}
            colorScheme={filter === "NodeJs" ? "teal" : "gray"}
            color={filter === "NodeJs" ? "black" : textColor}
          >
            NodeJS
          </Button>
        </Flex>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          gap="25px"
          alignItems="center"
          justifyContent="center"
        >
          {filteredProjects.map((project, index) => (
            <ProjectCard
              key={index}
              projectImage={project.projectImage}
              projectName={project.projectName}
              aboutProject={project.aboutProject}
              techStack={project.techStack}
              projectLink={project.projectLink}
              projectLiveLink={project.projectLiveLink}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
